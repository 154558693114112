import React from 'react';
import DashedLogo from '../images/roobka-dashed.svg';

export default function Loading() {
  return (
    <div id="loading-screen">
      <div className="loading-bg" />

      <div className="logo">
        <img src={DashedLogo} alt="Roobka icon" />
        <svg
          viewBox="0 0 372 375"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="filled"
        >
          <path
            d="M0.522461 299.139H70.6101C73.3715 299.139 75.6101 301.378 75.6101 304.139V360.068C75.6101 364.416 80.7773 366.692 83.9854 363.757L149.073 304.2C150.108 303.252 150.698 301.914 150.698 300.511V224.19H12.343C7.92436 224.19 5.6776 218.879 8.75498 215.708L70.6733 151.911H214.841C219.259 151.911 222.841 155.493 222.841 159.911V363.011C222.841 367.433 228.16 369.678 231.329 366.594L296.947 302.713V88.4266C296.947 82.9037 292.47 78.4266 286.947 78.4266H9.7019C6.20824 78.4266 4.39354 74.2621 6.77215 71.7032L69.3106 4.4251C70.8241 2.79683 72.947 1.87179 75.1701 1.87178L360.562 1.8711C366.085 1.87109 370.562 6.34824 370.562 11.8711L370.563 374.857"
            stroke="white"
            strokeWidth="3"
          />
        </svg>
      </div>
    </div>
  );
}
