import React from 'react';
import gsap from 'gsap';
import { AnimatePresence } from 'framer-motion';
import Loading from './src/components/Loading';
// import { pageAnim } from './src/utilities/pageTransitions';

export const wrapPageElement = ({ element }) => (
  <>
    <Loading />
    {/* <div id="page-transition">
      <div className="box">
        <div className="wrapper">
          <span className="text">Roobka</span>
        </div>
      </div>
    </div> */}
    <AnimatePresence mode="wait">{element}</AnimatePresence>
  </>
);

export const onInitialClientRender = () => {
  if (document.querySelector('#loading-screen')) {
    const loadingTl = gsap.timeline();
    const loadingScreen = document.querySelector('#loading-screen');

    loadingTl.set(loadingScreen, {
      opacity: 1,
      visibility: 'visible',
    });
    loadingTl.fromTo(
      loadingScreen,
      { opacity: 1 },
      {
        duration: 0.8,
        opacity: 0,
        ease: 'power3.out',
      },
      '+=0.8'
    );
    loadingTl.set(loadingScreen, {
      opacity: 0,
      visibility: 'hidden',
    });
  }

  window.heroDelay = 1.4;
};

// export const onRouteUpdate = ({ location }) => {
//   const pageName = location.pathname.split('/').join('').split('-').join(' ');
//   console.log(pageName || 'Roobka');
//   pageAnim(pageName);
// };

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // transition duration from `layout.js` * 1000 to get time in ms
  // * 2 for exit + enter animation, ignored *2 here
  const TRANSITION_DELAY = 0.8 * 1000;

  // if it's a "normal" route
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
  }

  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0];
    window.setTimeout(
      () => window.scrollTo(...savedPosition),
      TRANSITION_DELAY
    );
  }

  return false;
};
